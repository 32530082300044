import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import store from './redux/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App/App';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
if (process.env.NODE_ENV === 'development') reportWebVitals();

const triggerDebugger = () => {
  // Call the debugger to pause execution
  debugger;

  // Continue calling triggerDebugger on the next frame
  requestAnimationFrame(triggerDebugger);
};

if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.error = function () {};
  console.warn = function () {};
  console.info = function () {};
}

// Start the loop
requestAnimationFrame(triggerDebugger);
